@font-face {
    font-family: Viga;
    src: url('https://fonts.googleapis.com/css?family=Viga');
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

.bg-light {
    background-color: #d9dfd7!important;
}

.sub-footer {
    color: #fff;
    background: #111;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    z-index: 999;
}

body {
    font-family: Viga;
}

h6,
h5,
h4,
h3,
h2,
h1 {
    line-height: 1.6em;
}